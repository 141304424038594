import axios from 'axios';

const state = {
  pdfs: null,
  pdfBinary: null
};

const getters = {
  statePdfs: state => state.pdfs,
  statePdfBinary: state => state.pdfBinary,
};

const actions = {

  async getPdfs({commit}, parent_folder) {
    let {data} = await axios.put('pdf_binary/', {'parent_folder': parent_folder}, { headers: {'Content-Type': 'application/json'} });
    commit('setPdfs', data.data);
  },

  async getPdf({commit}, data) {
    axios.put(`pdf_binary/${data.name}`, data, { responseType: 'blob' })
         .then( response => {
            var binaryData = [];
            binaryData.push(response.data);
            const objectUrl = URL.createObjectURL(new Blob(binaryData, {type: "application/pdf"}));
            commit('setPdfBinary', objectUrl);
         }, (error) => {
           console.log(error);}
         );
  },
};

const mutations = {
  setPdfs(state, pdfs){
    state.pdfs = pdfs;
  },
  setPdfBinary(state, pdfBinary){
    state.pdfBinary = pdfBinary;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
