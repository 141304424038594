<template>
<div>
  <iframe height="100%" width=100% :src="`${getFilePath}`" ></iframe>
</div>
</template>

<script>
export default {

  name: 'PDFJSViewer',

  props: {
    fileName: String,
  },

  created () {
  },

  computed:{
    getFilePath: function () {
      if(this.fileName!==''){
          return './../lib/web/viewer.html?file=' + this.fileName
      }
      console.log('ERROR: PDFJSViewer called with empty fileName')
      return 'lib/web/viewer.html'
    }
  }
}
</script>

<style scoped>
div {
  width: 95%;
  height: 79vh;
}
</style>