<template>
  <b-container fluid class="bg-primary">
    <b-row class="h-100">
      <b-col>
        <div class="h-100 d-flex flex-column">

          <b-row class="flex-grow-1"></b-row>

          <b-row align-self="center" align-h="center">
            <b-card md="auto"
                    :img-src="require('../assets/images/lexai_login.png')"
                    title="Sign Up for free">

              <form @submit.prevent="submit">

                <alert :variant="alertVariant" :message="alertMessage" v-if="showAlert"></alert>

                <b-input-group>
                  <b-input-group-prepend is-text>
                    <b-icon icon="envelope-fill"></b-icon>
                  </b-input-group-prepend>
                  <b-form-input type="email" v-model="user.username" placeholder="email" autocomplete="username"></b-form-input>
                </b-input-group>

                <b-input-group class="mt-2">
                  <b-input-group-prepend is-text>
                    <b-icon icon="lock-fill"></b-icon>
                  </b-input-group-prepend>

                  <b-form-input type="password" v-model="user.password" placeholder="password" autocomplete="new-password"
                              :state="passwordState" aria-describedby="input-live-help input-live-feedback"></b-form-input>
                </b-input-group>
                <!-- This will only be shown if the preceding input has an invalid state -->
                <b-form-invalid-feedback id="input-live-feedback">Invalid Password</b-form-invalid-feedback>
                <!-- This is a form text block (formerly known as help block) -->
                <b-form-text id="input-live-help">Password must be at least 8 characters long</b-form-text>

                <br/>

                <b-button block type="submit" variant="primary" :disabled="!passwordState">Sign Up</b-button>

              </form>

              <template #footer>
                <p>Already registered? <a href="/login">Log In</a></p>
              </template>

            </b-card>
          </b-row>

          <b-row class="flex-grow-1"></b-row>

          <b-row>
            <metis_footer/>
          </b-row>

        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios';
import Alert from '@/components/Alert.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'Register',

  components: {
    alert: Alert,
    metis_footer: Footer
  },

  data() {
    return {
      user: {
        username: '',
        password: '',
      },

      responseStatusCode: 0,

      alertMessage: '',
      showAlert: false,
    };
  },

  computed: {
    passwordState() {
      return this.user.password.length > 7 ? true : false
    },
    alertVariant() {
      return this.responseStatusCode == 200 ? 'success' : 'danger'
    }
  },

  methods: {
    submit() {
      axios.post('pre_register', this.user, {validateStatus: () => true})
        .then( response => {
          this.responseStatusCode = response.status;
          this.alertMessage = response.data.message;
          this.showAlert = true;
        }).catch(error => {
              console.log('error:', error);
      })
    },
  },
};
</script>