<template>
  <b-container>

    <!-- search bar -->
    <b-row class="mb-2">

      <b-alert variant="warning" :show="disableSearch">
        Your document is being processed, the search will be available in a moment
      </b-alert>

      <b-alert variant="success" :show="successCountDown">
        Voilà! Your document is ready for search
      </b-alert>

      <b-form-input
                  placeholder="Search"
                  v-model="searchQuery"
                  @keydown.enter.prevent="submitSearch()"
                  :disabled="disableSearch">
      </b-form-input>

    </b-row>

    <!-- main -->
    <b-row>

      <!-- pdf viewer -->
      <b-col cols="8">
        <PDFJSViewer :fileName="pdfBinary"/>
      </b-col>

      <!-- search side panel -->
      <b-col>
        <div v-if="loadingQuery">
          <h2>Search in Progress...</h2>

          <p>{{ sentSearchQuery }}</p>

          <div class="d-flex justify-content-center mb-3">
            <b-spinner variant="primary" style="width: 3rem; height: 3rem;"></b-spinner>
<!--          <semipolar-spinner :animation-duration="2000" :size="65" color="#ff1d5e"/>-->
          </div>

        </div>

        <div v-if="searchResults">
          <h2>Search Results</h2>

          <p>{{ sentSearchQuery }}</p>

          <div v-for="i in this.keySentences.length" :key="i">
            <b-card class="mb-1"
                    :header-bg-variant="keyMatchColors[i-1]"
                    :header-text-variant="keyMatchColors[i-1] == 'warning' ? 'dark' : 'light'">
              <template #header>
                <h6 class="mb-0">
                  Score: {{ searchResults.key_distances[i-1] }}
                  <span style="float:right;">
                    Page: {{ searchResults.key_pages[i-1] }}
                  </span>
                </h6>
              </template>
              <b-card-text v-html="searchResults.key_sentences[i-1]"></b-card-text>
            </b-card>
          </div>
        </div>
      </b-col>

    </b-row>

    <!-- error modals -->
    <b-modal id="search-error-modal" title="Something went wrong!" ok-only no-close-on-backdrop hide-header-close>

      <p v-if="!issueSubmitted">
      Please try again. The error persists? Please <a @click="submitIssue()" class="text-primary"><u>report issue</u></a>
      </p>

      <b-alert variant="success" :show="issueSubmitted">Thank you for reporting the issue, we will work on it</b-alert>

    </b-modal>

  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import PDFJSViewer from '@/components/PDFJSViewer'
// import { SemipolarSpinner  } from 'epic-spinners'

export default {
  name: 'PdfExplorer',

  props: ['name'],

  components: {
    PDFJSViewer
  },

  data () {
    return {
      disableSearch: true,
      successCountDown: 0,
      searchQuery: '',
      sentSearchQuery: '',
      loadingQuery: false,
      keyMatchColors: [],
      keySentences: [],
      issueType: '',
      issueInfo: {},
      issueSubmitted: false,
      current_folder: 'home'
    }
  },

  computed: {
    ...mapGetters({ pdfBinary: 'statePdfBinary',
                    searchResults: 'stateSearchResults'}),
  },

  methods: {
    submitSearch() {
      // --- set up ---
      console.log("Submitting search");
      this.$store.commit('resetSearchState');
      this.loadingQuery = true;
      this.sentSearchQuery = this.searchQuery;
      let payload = {
        name: this.name,
        parent_folder: this.current_folder,
        searchKey: this.searchQuery,
      };
      // --- submit search ---
      axios.post(`search/`, payload)
        .then( response => {
          console.log("Collecting search results")
          // throw error if response.data.code != 200
          if (response.data.code != 200) {
            throw 'Error in search!';
          }
          this.$store.commit('setSearchResults', response.data);
          // select sentences to display
          this.keySentences = [];
          this.keyMatchColors = [];
          for (var i = 0; i < this.searchResults.key_distances.length; i++) {
            let score_i = this.searchResults.key_distances[i]
            if ( (score_i >= 0.5) || (i == 0) ) {
              // add sentence
              this.keySentences.push(this.searchResults.key_sentences[i]);
              // compute color
              var color = ''
              if ( score_i < 0.5 ) {
                color = 'danger'
              } else if ( score_i < 0.75 ) {
                color = 'warning'
              } else {
                color = 'success'
              }
              this.keyMatchColors.push(color);
            }
          }
          this.loadingQuery = false;
          this.searchQuery = '';

          // --- highlight pdf ---
          let payload = {
            name: this.name,
            parent_folder: this.current_folder,
            key_sentences: this.keySentences
          };
          axios.post(`search/highlighted_pdf/${this.name}`, payload, { responseType: "blob" })
            .then( response => {
              // TODO: throw error
              var binaryData = [];
              binaryData.push(response.data);
              const objectUrl = URL.createObjectURL(new Blob(binaryData, {type: "application/pdf"}));
              this.$store.commit('setPdfBinary', objectUrl);
            })
            .catch(error => {
              console.log('error in highlighted_pdf:', error);
            })
        })
        .catch(error => {
          // error in search
          console.log(error);
          this.issueType = 'search';
          this.issueInfo = {'search_query': this.searchQuery};
          this.$store.commit('resetSearchState');
          this.loadingQuery = false;
          this.$bvModal.show('search-error-modal');
        })
    },

    submitIssue() {
      // send request
      let payload = {
        name: this.name,
        parent_folder: this.current_folder,
        issue_type: this.issueType,
        issue_info: this.issueInfo
      };
      axios.post('reported_issue', payload)
      // display alert
      this.issueSubmitted = true
    },

  },

  created() {
    try {
      console.log("loading plain pdf")
      this.$store.commit('resetSearchState')
      this.$store.dispatch('getPdf', {'name': this.name, 'parent_folder': this.current_folder})
      axios.post(`extracted_text/${this.name}`, {'name': this.name, 'parent_folder': this.current_folder}, { headers: {'Content-Type': 'application/json'} })
        .then( response => {
          // throw error if response.data.code != 200
          if (response.data.code != 200) {
            throw 'extracted_text';
          }
          axios.post(`preprocessed/${this.name}`, {'name': this.name, 'parent_folder': this.current_folder}, { headers: {'Content-Type': 'application/json'} })
            .then( response => {
              // throw error if response.data.code != 200
              if (response.data.code != 200) {
                throw 'preprocessed';
              }
              this.disableSearch = false
              this.successCountDown = 5
            })
        })
    } catch (error) {
      // error in processing pdf
      console.log('Error in:', error);
      this.issueType = error;
      this.issueInfo = {};
      this.$bvModal.show('search-error-modal');
    }
  },

  mounted() {
    // listen to modal hiding
    this.$root.$on('bv::modal::hide', () => {
      this.$router.push('/dashboard');
    })
	},

//TODO: add this.$store.commit('resetPdf') on leaving this page

};
</script>