<template>
  <b-container>

    <!-- main -->
    <b-row>

      <!-- search side panel -->
      <b-col>
<!--        <div v-if="loadingQuery">-->
<!--          <h2>Search in Progress...</h2>-->

<!--          <p>{{ sentSearchQuery }}</p>-->

<!--          <div class="d-flex justify-content-center mb-3">-->
<!--            <b-spinner variant="primary" style="width: 3rem; height: 3rem;"></b-spinner>-->
<!--          </div>-->

<!--        </div>-->

        <div v-if="searchResults">
          <h2>Search Results</h2>

          <p>{{ searchResults.search_query }}</p>

          <div v-for="i in this.keySentences.length" :key="i">
            <b-card class="mb-1"
                    :header-bg-variant="keyMatchColors[i-1]"
                    :header-text-variant="keyMatchColors[i-1] == 'warning' ? 'dark' : 'light'">
              <template #header>
                <h6 class="mb-0">
                  {{ searchResults.key_pdfs[i-1] }} - page {{ searchResults.key_pages[i-1] }}
                  <span style="float:right;">
                    Score: {{ searchResults.key_distances[i-1] }}
                  </span>
                </h6>
              </template>
              <b-card-text v-html="searchResults.key_sentences[i-1]"></b-card-text>
            </b-card>
          </div>
        </div>

      </b-col>

    </b-row>

  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SearchResults',

  props: [],

  components: {
  },

  data () {
    return {
      //sentSearchQuery: '',
      //loadingQuery: false,
      keyMatchColors: [],
      keySentences: [],
    }
  },

  computed: {
    ...mapGetters({ searchResults: 'stateSearchResults'}),
  },

  created() {
    try {
      // select sentences to display
      this.keySentences = [];
      this.keyMatchColors = [];
      for (var i = 0; i < this.searchResults.key_distances.length; i++) {
        let score_i = this.searchResults.key_distances[i]
        if ( (score_i >= 0.5) || (i == 0) ) {
          // add sentence
          this.keySentences.push(this.searchResults.key_sentences[i]);
          // compute color
          var color = ''
          if ( score_i < 0.5 ) {
            color = 'danger'
          } else if ( score_i < 0.75 ) {
            color = 'warning'
          } else {
            color = 'success'
          }
          this.keyMatchColors.push(color);
        }
      }
      //this.loadingQuery = false;
      //this.searchQuery = '';
    } catch (error) {
      // error in displaying search results
      console.log('Error in:', error);
    }
  },

};
</script>