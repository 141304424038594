import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";

import users from './modules/users';
import pdfs from './modules/pdfs';
import searches from './modules/searches';


Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    users,
    pdfs,
    searches
  },
  plugins: [createPersistedState()]
});