<template>
  <b-container>

    <!-- upload -->
    <b-row class="mb-3">
      <h3>Upload Documents</h3>
      <b-form-file
        accept=".pdf"
        v-model="file"
        :state="Boolean(file)"
        placeholder="Drop here a pdf file or click to browse your computer..."
        drop-placeholder="Drop file here..."
      ></b-form-file>
    </b-row>

    <b-row class="mb-1">
      Selected file: {{ file ? file.name : '' }}
      <b-button variant="danger" @click="file = null" v-if="Boolean(file)" class="ml-3">Discard</b-button>
    </b-row>

    <b-row class="mb-4">
      <b-button variant="primary" @click="submitFile()" :disabled="!Boolean(file)">Upload pdf</b-button>
    </b-row>

    <hr/>

    <!-- search bar -->
    <b-row class="mb-4">
      <h3>Search Your Documents</h3>
      <b-form-input
                  placeholder="Search"
                  v-model="searchQuery"
                  @keydown.enter.prevent="submitSearch()">
      </b-form-input>
      <h4 v-if="emptyResults" class="text-danger mt-2"> Your search returned no results! </h4>
    </b-row>

    <!-- search in progress spinner -->
    <b-row v-if="loadingQuery">
      <h3>Search in Progress...</h3>
      <b-spinner variant="primary" style="width: 3rem; height: 3rem;" class="ml-3"></b-spinner>
    </b-row>

    <!-- documents table -->
    <b-row v-else>
      <table class="table table-hover" v-if="pdfs.length">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Created</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="pdf in pdfs" :key="pdf.name">
            <td>{{ pdf.name }}</td>
            <td>{{ pdf.created }}</td>
            <td>
              <div class="btn-group" role="group">
                <b-button variant="success" :to="{name: 'PdfExplorer', params:{name: pdf.name}}">Open</b-button>
                <b-button variant="danger" @click="deletePdf(pdf.name)">Delete</b-button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </b-row>

    <!-- error modals -->
    <b-modal id="upload-error-modal" title="The pdf cannot be uploaded" ok-only no-close-on-backdrop hide-header-close>

      <p>
      {{ this.errorModalText }}
      </p>

    </b-modal>

    <b-modal id="search-error-modal" title="Something went wrong!" ok-only no-close-on-backdrop hide-header-close>

      <p v-if="!issueSubmitted">
      Please try again. The error persists? Please <a @click="submitIssue()" class="text-primary"><u>report issue</u></a>
      </p>

      <b-alert variant="success" :show="issueSubmitted">Thank you for reporting the issue, we will work on it</b-alert>

    </b-modal>

  </b-container>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'Dashboard',

  data () {
    return {
      file: '',
      current_folder: 'home',
      errorModalText: '',
      searchQuery: '',
      sentSearchQuery: '',
      loadingQuery: false,
      issueType: '',
      issueInfo: {},
      issueSubmitted: false,
      emptyResults: false
    };
  },

  computed: {
    ...mapGetters({ pdfs: 'statePdfs' }),
  },

  methods: {
    async submitFile() {
      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('parent_folder', this.current_folder);
      axios.post(`pdf_binary/${this.file.name}`, formData, { headers: {'Content-Type': 'multipart/form-data'} })
        .then( response => {
          console.log('response:', response.data.code, ' - ', response.data.message);
          // throw error if response.data.code != 200
          if (response.data.code != 200) {
            this.errorModalText = response.data.message
            throw 'Error uploading pdf!';
          }
          this.$store.dispatch('getPdfs', this.current_folder);
          this.$router.push({name:'PdfExplorer', params: {name: this.file.name}});
        })
        .catch(error => {
          // error in uploading pdf
          console.log(error);
          this.$bvModal.show('upload-error-modal');
        })
    },

    submitSearch() {
      // --- set up ---
      console.log("Submitting search");
      this.$store.commit('resetSearchState');
      this.loadingQuery = true;
      this.sentSearchQuery = this.searchQuery;
      this.emptyResults = false;
      let payload = {
        name: '',
        parent_folder: this.current_folder,
        searchKey: this.searchQuery,
      };
      // --- submit search ---
      axios.post(`search/`, payload)
        .then( response => {
          console.log("Collecting search results")
          // throw error if response.data.code != 200
          if (response.data.code != 200) {
            throw 'Error in search!';
          }
          if (response.data.key_sentences.length != 0) {
            this.$store.commit('setSearchResults', response.data);
            this.$router.push({name:'SearchResults'});
          } else {
            this.$store.commit('resetSearchState');
            this.loadingQuery = false;
            this.sentSearchQuery = '';
            this.emptyResults = true;
          }

        })
        .catch(error => {
          // error in search
          console.log(error);
          this.issueType = 'search';
          this.issueInfo = {'search_query': this.searchQuery};
          this.$store.commit('resetSearchState');
          this.loadingQuery = false;
          this.$bvModal.show('search-error-modal');
        })
    },

    async deletePdf(id) {
      if(confirm("Do you really want to delete this document?")){
        try {
          let payload = {
            name: id,
            parent_folder: this.current_folder,
          };
          console.log(payload)
          await axios.delete(`pdf_binary/${id}`, { data: payload });
          this.$store.dispatch('getPdfs', this.current_folder);
        } catch (error) {
          console.error(error);
        }
      }
    },

    submitIssue() {
      // send request
      let payload = {
        name: this.name,
        parent_folder: this.current_folder,
        issue_type: this.issueType,
        issue_info: this.issueInfo
      };
      axios.post('reported_issue', payload)
      // display alert
      this.issueSubmitted = true
    },

  },

  created: function() {
    return this.$store.dispatch('getPdfs', this.current_folder);
  },

  mounted() {
    // listen to modal hiding
    this.$root.$on('bv::modal::hide', () => {
      this.$router.push('/dashboard');
    })
	},

};
</script>