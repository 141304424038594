<template>
  <b-container>

    <h1>Your Profile</h1>
    <hr/><br/>

    <div>
      <p><strong>Username:</strong> <span>{{ user.username }}</span></p>
      <p><b-button @click="deleteAccount()" variant="danger">Delete Account</b-button></p>
    </div>

  </b-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Profile',

  created: function() {
    return this.$store.dispatch('viewMe');
  },

  computed: {
    ...mapGetters({user: 'stateUser' }),
  },

  methods: {
    ...mapActions(['deleteUser']),
    async deleteAccount() {
      if(confirm("Do you really want to delete your profile and all your data?")){
        try {
          await this.deleteUser(this.user.username);
          await this.$store.dispatch('logOut');
          this.$router.push('/');
        } catch (error) {
          console.error(error);
        }
      }
    }
  },
}
</script>