const getDefaultSearchState = () => {
  return {
    searchResults: null
  }
}

// initial state
const state = getDefaultSearchState()

const getters = {
  stateSearchResults: state => state.searchResults,
};

const actions = {
};

const mutations = {
  setSearchResults (state, payload) {
    state.searchResults = payload;
  },

  resetSearchState (state) {
    Object.assign(state, getDefaultSearchState())
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
