import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';

import Register from '@/views/Register';
import Welcome from '@/views/Welcome';
import Login from '@/views/Login';
import Profile from '@/views/Profile';
import Dashboard from '@/views/Dashboard';
import SearchResults from '@/views/SearchResults';
import PdfExplorer from '@/views/PdfExplorer';
import UpdatePassword from '@/views/UpdatePassword';
import ConfirmNewPassword from '@/views/ConfirmNewPassword';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: { name: 'Dashboard' }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/welcome/:token',
    name: 'Welcome',
    component: Welcome,
    props: true
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {requiresAuth: true},
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {requiresAuth: true},
  },
  {
    path: '/search_results',
    name: 'SearchResults',
    component: SearchResults,
    meta: {requiresAuth: true},
  },
  {
    path: '/pdf_explorer/:name',
    name: 'PdfExplorer',
    component: PdfExplorer,
    meta: {requiresAuth: true},
    props: true
  },
  {
    path: '/update_password',
    name: 'UpdatePassword',
    component: UpdatePassword,
  },
  {
    path: '/confirm_new_password/:token',
    name: 'ConfirmNewPassword',
    component: ConfirmNewPassword,
    props: true
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// Navigation Guard: redirect to the /login route if not authenticated
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next('/login');
  } else {
    next();
  }
});

// Navigation Guard: change page title
router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = to.meta.title || 'Lex AI';
    });
});

export default router;