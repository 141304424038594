<template>
  <b-container fluid class="bg-primary">
    <b-row class="h-100">
      <b-col>
        <div class="h-100 d-flex flex-column">

          <b-row class="flex-grow-1"></b-row>

          <b-row align-self="center" align-h="center">
            <b-card md="auto"
                    :img-src="require('../assets/images/lexai_login.png')">

              <b-alert show variant="success">
                Registration complete, please <a href="/login">log in</a>
              </b-alert>

            </b-card>
          </b-row>

          <b-row class="flex-grow-1"></b-row>

          <b-row>
            <metis_footer/>
          </b-row>

        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Footer from '@/components/Footer.vue';
import { mapActions } from 'vuex';

export default {
  name: 'Welcome',

  props: ['token'],

  components: {
    metis_footer: Footer
  },

  data () {
  },

  computed: {
  },

  methods: {
    ...mapActions(['register']),
  },

  created() {
    try {
      this.register(this.token);
    } catch (error) {
      console.error(error);
      this.$router.push('/login');
    }
  },

  mounted() {
	},

};
</script>